import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

export interface AppState {
  hoge: string;
  loaded: boolean;
}

export interface BallInformation {
  title: string;
  path: string;
  imageUrl: string;
  count: number;
}

@Module({ dynamic: true, store, name: 'appModule' })
class App extends VuexModule implements AppState {
  public hoge = 'hogehoge';
  public loaded = false;
  @Action
  public setHoge(hoge: string) {
    this.SET_HOGE(hoge);
  }

  @Mutation
  private SET_HOGE(hoge: string) {
    this.hoge = hoge;
  }

  @Action
  public setLoaded(loaded: boolean) {
    this.SET_LOADED(loaded);
  }

  @Mutation
  private SET_LOADED(loaded: boolean) {
    this.loaded = loaded;
  }

}
export const AppModule = getModule(App);
