import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
extend('required', { ...required, message: "{_field_}は必須です" });
extend('email', { ...email, message: "正しいメールアドレスを入力してください" });
extend('confirmed', { ...confirmed, message: "{_field_}が異なります" });

import VueHead from 'vue-head'
Vue.use(VueHead, {
  complement: '大阪電気通信大学'
})
Vue.config.productionTip = false

import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  config: { id: "G-SNHNCSY1C7" }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
